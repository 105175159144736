import React from 'react'
import { CircularProgress, Container, Fab, IconButton } from '@material-ui/core';
import Dashboard from "../Dashboard"
import GameService from '../../services/game-service';
import GameProperties from './game-properties';
import Page404 from '../template/Page404';
import { Add, Delete, Edit } from '@material-ui/icons';
import {notify} from "../../redux/reduxFunctions";
import ConfirmDialog from "../template/ConfirmDialog"
import GameForm from './game-form';



class Games extends React.Component{

    state = {
        games: null,
        confirmTitle: null,
        confirmPrompt: null,
        onConfirm: null,
        editGame: null
    }

    componentDidMount(){
        this.fetchData();
    }


    update = async (data) => {

        let res;
        if( !data.id ){
            res = await GameService.add(data);
        } else {
            res = await GameService.update(data);
        }

        if( !res ) return notify( GameService.error || "Unknown error");

        // close the form
        this.setState({editGame: null})

        // update data
        this.fetchData();
    }

     delete = async(id) => {
        this.closeConfirmDialog();

        if( !await GameService.delete(id)){
            return notify(GameService.error || "Unknown error");
        }
        
        // reload
        this.fetchData();
    }

    closeConfirmDialog = () => {
        this.setState({onConfirm: null, confirmPrompt: null, confirmTitle: null})
    }

    fetchData = async () => {
        let games = await GameService.getAll();

        games = games.map( t => ({...t, to: `/games/${t.id}`, _children: [
            {
                itemType: "text",
                text: `Teams: ${t?.teams?.length || 0}`                
            },
            {
                itemType: "text",
                text: `Sections: ${t?.sections?.length || 0}`                
            },            
            {
                itemType: "content",
                content: 
                <div className="flex row">

                    <IconButton color="primary" onClick={ () => {    
                        this.setState(
                            {
                                editGame: t
                            })
                    }
                        }><Edit/> </IconButton>
                    <IconButton color="secondary" onClick={ () => {
    
                        this.setState(
                            {
                                confirmTitle: "Delete game",
                                confirmPrompt: `Do you really want to delete the game "${t.title}"?`,
                                onConfirm: () => this.delete(t.id)
                            })
                    }
                        }><Delete/> </IconButton>
                </div>
            }
        ]}))

        this.setState({games});
    }

    render(){

        const {games, confirmPrompt, confirmTitle, onConfirm, editGame} = this.state;
        console.debug( "props: ", this.props )
        let {id, section_id} = this.props.match.params;
        // cast to int
        id = id ? parseInt(id) : null;

        
        if( !games ) return <CircularProgress />

        console.debug("id:", id)
        const item = (id && Array.isArray(games)) ? games.find( i => i.id === id) : null;
        console.debug("item:", item)

        if( isNaN(id) ||  (id && !item) ) return <Page404 />

        if( item ) return <GameProperties {...item} section_id={section_id} onUpdate={this.fetchData} />

        return(
            <Container>
                <Dashboard
                    items={games}
                    showSearch={true}
                />
                <ConfirmDialog open={Boolean(confirmPrompt)} header={confirmTitle} prompt={confirmPrompt} onConfirm={onConfirm} onClose={this.closeConfirmDialog} />
                <GameForm formClassName="shrink" open={Boolean(editGame)} game={editGame} onClose={()=> this.setState({editGame: null})} onSave={this.update} />
                <Fab color="primary" className="fab" onClick={() => this.setState({editGame: {...GameService.defaultGame}})}><Add/> </Fab>
            </Container>
        )
    }
}

export default Games