import { Button, Container, IconButton, Typography } from '@material-ui/core'
import { Add, Description, Edit } from '@material-ui/icons';
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import TextInputDialog from '../template/text-input-dialog';
import ContentService from '../../services/content-service';
import { notify } from '../../redux/reduxFunctions';
import GameService from '../../services/game-service';
import ModalDialog from '../template/modal-dialog';
import Editor from '../template/tinymce-editor';
import GameQuestions from './game-questions';


class GameSectionContent extends React.Component{

    state = {
        item: null,
        inputDialogProps: null,
        contentItems: null
    }


    componentDidMount(){
        this.fetchData();
    }

    componentDidUpdate(prevProps){
        if( JSON.stringify(this.props.content) !== JSON.stringify(prevProps.content)) {
            this.fetchData()
        }
    }

    fetchData = async () => {
        const {content} = this.props;

        const contentItems = content?.length > 0 ? await ContentService.getByIds(content) : [];
        this.setState({contentItems})
    }


    showInputDialog = (inputDialogProps) => this.setState({inputDialogProps});

    closeInputDialog = () => this.setState({inputDialogProps: null})


    addContent = async (title) => {
        console.debug( "addContent called with", title);
        const {game, order, onUpdate} = this.props;
        if( !await GameService.addContentToSection( game.id, order, {title}) ){
           return notify(GameService.error || "Unknown Error")
        }
        this.closeInputDialog();
        onUpdate();
    }

    onContentUpdate = async (data) => {
        
        const {order, game} = this.props;
        const {id} = data 
        if( !(id >=0 )) return notify ("Не получен ID материала")
        
        if( !await GameService.updateSectionContent(game.id, order, id, data)){
            return notify(GameService.error || "Unknown Error");
        }
        // close modal
        this.setState({item:null})
        // close input
        this.closeInputDialog();

        // fetch data again
        this.fetchData();

        // notify
        notify( "Материал сохранен","success")
    }

    render(){
        let {order, game } = this.props;
        let {item, inputDialogProps, contentItems = []} = this.state;


        contentItems = Array.isArray(contentItems) ? contentItems : []


        return(
        <Container>
            <div className="game-section-content flex column items-stretch">
                <Typography variant="h3">Игра "{game.title}", фаза {order+1}</Typography>
                <Button variant="contained" component={Link} to={`/games/${game.id}`}>Назад</Button>

                <Typography variant="h5">Материалы</Typography>
                <div className="flex row content-space-between">
                    <Button variant="contained" color="primary" onClick={
                        () => this.showInputDialog({
                            onConfirm: this.addContent,
                            onClose: this.closeInputDialog,
                            header: "Введите название материала",
                            value: ""
                        })
                    }><Add/></Button>
                </div>
                <table className="striped">                    
                    <tbody>
                        {
                            contentItems.map( c => <tr key={`content-${c.id}`}>
                                <td>{c.title}<IconButton onClick={
                                        () => this.showInputDialog({
                                            onConfirm: (title) => this.onContentUpdate( {id: c.id, title}),
                                            onClose: this.closeInputDialog,
                                            header: "Введите название материала",
                                            value: c.title
                                        })
                                     }><Edit/></IconButton></td>
                                <td><IconButton onClick={() => this.setState({item: c})}><Description/></IconButton></td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>

            {inputDialogProps !== null && <TextInputDialog open={true} {...inputDialogProps || {}} /> }
            { item && <ModalDialog open={true} onClose={() => this.setState({item: null})}>
                <Editor content={item.content} onSave={(content) => this.onContentUpdate({id: item.id, content})} onCancel={() => this.setState({item: null})}/>
            </ModalDialog> }
        </Container>
        )
    }
}

export default GameSectionContent