import BaseClientService from  "./BaseClientService"
import { saveProgress } from "../redux/dbActions";
import store from "../redux/store";
import TeamService from "./team-service";
import { arraysIntersect } from "../utils/functions";

class GameProgressService extends BaseClientService {

    answersList = null

    static async getAll(){
        if( !this.answersList ){
            // this.answersList = [...games];
            this.answersList = store.getState().db.progress;
        
        }
        const t = this.answersList;
        console.debug("game progress:", t)
        
        return t;
    }

    static async add( data ){
        
        // check if answer already exists 
        const all = await this.getAll();
        if( all.find( ga => ga.game_id === data.game_id && ga.question_id === data.question_id && data.team_id === ga.team_id ) ){
            return this.Error("На этот вопрос команда уже ответила")
        }

        const id = await this.getNextId();
        
        const newElement = {...this.defaultAnswer, ...data, id};
        console.debug("adding progress", newElement )

        // add element
        this.answersList = [ ... await this.getAll(), 
            newElement    
        ]

        // update state
        saveProgress(this.answersList);

        return id;
    }

    static async update( data ){
        const {id, ...other} = data;

        if(!id) return this.Error("empty id");
        const list = await this.getAll();
        const itemIndex = list.findIndex( t => t.id === id );
        if( itemIndex < 0 ) return this.Error(`Item with id [${id}] was not found`)

        this.answersList[itemIndex] = {...list[itemIndex], ...other}

        // update state
        saveProgress(this.answersList);

        return true

    }

    static async delete(id){
        id = parseInt(id);
        const itemIndex = (await this.getAll()).findIndex( t => t.id === id );

        if( !(itemIndex >= 0) ) return this.Error(`Item with id [${id}] was not found`)

        // remove
        this.answersList.splice(itemIndex, 1);

        // update state
        saveProgress(this.answersList);

        return true;
    }

    static async getTeamAnswers( team_id, game_id){
        const all = await this.getAll();
        return all.filter( gp => gp.team_id === team_id && gp.game_id === game_id );
    }

    static async getGameAnswers( game_id){
        const all = await this.getAll();
        return all.filter( gp => gp.game_id === game_id );
    }

    static async getNextId(){
        // find maximum existing id and add 1
        // return Math.max( ... this.answersList.map( t => t.id) ) + 1;
        const all = await this.getAll();
        return Math.max( ...all.map( t => t.id), 0 ) + 1;
    }
}

BaseClientService.extend(GameProgressService);

GameProgressService.defaultAnswer = {
    id: null,
    "game_id": null,
    "team_id": null,
    "question_id": null,
    "answer_id": null,
    "section_id": null,
    "score": 0,
    "timestamp": ""
  }

export default GameProgressService;