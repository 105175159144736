import React, { useState } from 'react';
import Login from './login';
import Register from './register';
import { Tabs, Tab, Container, Typography } from '@material-ui/core';

const tabname = 'login-register-tabs';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${tabname}-panel-${index}`}
      aria-labelledby={`${tabname}-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const LoginRegister = (props) => {
  const [value, setValue] = useState(0);

  const {title = "New App"} = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className='wrapper-tabs'>
      <Typography variant='h1' color='primary'>
        {title}
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='login/register tabs'
      >
        <Tab label='Login' index={0} />
        <Tab label='Register' index={1} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Login />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Register />
      </TabPanel>
    </Container>
  );
};


export default LoginRegister;