import React from 'react'
import TeamService from '../../services/team-service';
import { Button, Typography } from '@material-ui/core';
import { notify } from '../../redux/reduxFunctions';
import SelectUser from '../users/select-user';
import { Link } from 'react-router-dom/cjs/react-router-dom';


class TeamProperties extends React.Component{

    state = {
        captainData: null,
        showSelect: false
    }

    componentDidMount(){
        this.fetchData();
    }

    componentDidUpdate( prevProps ){
        if( this.props.captain !== prevProps.captain ){
            this.fetchData();
        }
    }

    fetchData = async () => {
        const {id} = this.props

        let captainData = await TeamService.getTeamCaptain(id) ;
        if( !captainData) captainData = false

        this.setState({captainData})
    }
    
    showSelectCaptain = () => {
        this.setState({
            showSelect: true
        })
    }

    removeCaptain = async  () => {
        const {id, onUpdate} = this.props
        if( ! await TeamService.removeCaptain(id)){
            return notify( TeamService.error || "Unknown error")
        }

        onUpdate();
    }
    
    assignCaptain = async  (user) => {
        const {id: user_id} = user || {}
        const {id, onUpdate} = this.props
        if( ! await TeamService.assignCaptain(id, user_id )){
            return notify( TeamService.error || "Unknown error")
        }

        onUpdate();
    }

    render(){
        const {title, captain, score} = this.props;
        const {captainData, showSelect} = this.state;

        
        return(
            <div className="team-properties">
                <Typography variant="h1" className="flex content-space-between row items-center">
                    <span>Команда "{title}"</span>
                    <Button variant="contained" component={Link} to={`/teams`}>Назад</Button>
                    </Typography>
                <div className="captain-data">
                    <Typography variant="h3" className="m-top-10-forced">Очки: {score}</Typography>
                    <Typography variant="h3" className="m-top-10-forced">Капитан</Typography>
                    { captainData === false && <Button variant="contained" color="primary" onClick={this.showSelectCaptain}>Назначить</Button>}
                    { captainData && <>
                        <Typography className="color-teal" >{captainData.name}</Typography> 
                        <Button variant="contained" color="secondary" onClick={this.removeCaptain}>Удалить</Button>
                        <Button variant="contained" color="primary" onClick={this.showSelectCaptain}>Заменить</Button>
                    </>}
                    <SelectUser open={showSelect} alreadySelected={ captain ? [captain] : []} onClose={()=> this.setState({showSelect: false})} onSelect={this.assignCaptain} />
                </div>

            </div>
        )
    }
}

export default TeamProperties