/**
 * Returns true if arr1 contains at least one of elements of arr2
 * @param {*} arr1
 * @param {*} arr2
 * @returns
 */
export const arraysIntersect = (arr1, arr2) =>
  Array.isArray(arr1) &&
  Array.isArray(arr2) &&
  arr1.some((el) => arr2.includes(el));

  