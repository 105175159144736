import Home from '../components/StaticPages/Home';
import Profile from '../components/StaticPages/profile';
import CaptainHome from '../components/captain-home';
import Games from '../components/games';
import GameCurrentContent from '../components/games/game-current-content';
import PlayerHome from '../components/player-home';
import Teams from '../components/teams';
import Page404 from '../components/template/Page404';
import Users from '../components/users';



export const Switch = [
  {
    exact: true,
    path: '/',
    component: Home,    
    credentials: ["admin"]
  },

  {
    exact: true,
    path: '/',
    component: PlayerHome,    
    credentials: ["player"]
  },

  {
    exact: true,
    path: '/',
    component: CaptainHome,    
    credentials: ["captain"]
  },  
  {
    exact: true,
    path: '/game/:game_id',
    component: CaptainHome,    
    credentials: ["captain"]
  },  
  {
    exact: true,
    path: '/game/:game_id/question/:question_id',
    component: CaptainHome,    
    credentials: ["captain"]
  },  

  {
    exact: true,
    path: '/game/:game_id/content',
    component: GameCurrentContent,    
    credentials: ["captain"]
  },  
  
  { exact: false, path: '/profile', component: Profile },
  { exact: false, path: '/teams/:team_id?', component: Teams, credentials: ["admin"] },
  { exact: false, path: '/games/:id/section/:section_id/content', component: Games, credentials: ["admin"] },    
  { exact: false, path: '/games/:id?', component: Games, credentials: ["admin"] },  
  { exact: false, path: '/users/:id?', component: Users, credentials: ["admin"] },
  { exact: false, path: '*', component: Page404 },
];

export default Switch;
