import ActionTypes from './actionTypes';
import store from './store';


export const logOut = () => {
  console.log("dispatching logout")
  store.dispatch({type: ActionTypes.LOGOUT});  
};

export const replaceUser = ( payload ) => {
  console.log("dispatching replace user with", payload)
  store.dispatch({type: ActionTypes.REPLACE, payload});  
};
