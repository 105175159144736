import React from 'react'
import {Container, Grid, Button, Typography, CircularProgress, List, MenuItem, IconButton} from '@material-ui/core';
import { Add, Delete, Edit, Visibility } from '@material-ui/icons';
import {Link} from 'react-router-dom';
import TeamService from '../../services/team-service';
import GameService from '../../services/game-service';
import { notify } from '../../redux/reduxFunctions';
import SelectTeam from './select-team';
import ConfirmDialog from '../template/ConfirmDialog';
import { Alert } from '@material-ui/lab';
import GameSectionContent from './game-section-content';
import GameQuestions from './game-questions';
import TextInputDialog from '../template/text-input-dialog';


class GameProperties extends React.Component{

    state = {
        teamList: null,
        allTeams: null,
        showSelectTeam: false,
        // confirm dialog
        confirmTitle: null,
        confirmPrompt: null,
        onConfirm: null,

        textInputProps: null
    }


    componentDidMount(){
        this.fetchData();
    }

    componentDidUpdate(prevProps){
        if( JSON.stringify(prevProps.teams) !== JSON.stringify(this.props.teams) ){
            this.fetchData();
        }
    }


    fetchData = async () => {
        const {id} = this.props;
        // const teamList = await TeamService.getByIds(teams)
        const teamList = await GameService.getGameStats(id)
        const allTeams = await TeamService.getAll();
        this.setState({teamList, allTeams})
    }

    startGame = async () => {
        let {id, onUpdate, current_section, started_at} = this.props;
        if( started_at ){
            return notify("Игра уже начата!", "warning");
        }

        if( !await GameService.update( {id, current_section: 0, started_at: new Date().toLocaleString() } ) ){
            return notify( GameService.error || "Unknown error")
        }

        // update parent 
        onUpdate();

    }

    startSection = async () => {
        let {id, onUpdate, sections, current_section, started_at} = this.props;
        if( !started_at ){
            return notify("Игра еще не начата, нельзя начать фазу", "warning");
        }

        const now = new Date().toLocaleString();
        
        sections[current_section].started_at = now;
        

        if( !await GameService.update( {id, sections} ) ){
            return notify( GameService.error || "Unknown error")
        }

        // update parent 
        onUpdate();

    }
    
    finishSection = async () => {
        let {id, onUpdate, sections, current_section} = this.props;
        if( current_section === null ){
            return notify("Игра ещё не начата", "warning");
        }

        sections[current_section].finished_at = new Date().toLocaleString();

        if( !await GameService.update( {id, sections} ) ){
            return notify( GameService.error || "Unknown error")
        }

        // check if game is finished (this is the last section and we're closing it)
        const finishingGame = sections.length - 1 === current_section
        if( finishingGame && !await GameService.finishGame(id) ){
            // something happened when ending the game, notify
            return notify( GameService.error || "Unknown error")
        }

        // update parent 
        onUpdate();

    }

    resetSection = async () => {
        let {id, onUpdate, sections, current_section} = this.props;
        if( current_section === null ){
            return notify("Game is not started!", "warning");
        }

        sections[current_section].started_at = null;
        sections[current_section].finished_at = null;

        if( !await GameService.update( {id, sections} ) ){
            return notify( GameService.error || "Unknown error")
        }

        // update parent 
        onUpdate();

    }

    openNext = async () => {
        let {id, onUpdate, current_section, started_at } = this.props;
        if( !started_at ){
            return notify("Игра ещё не начата", "warning");
        }

        current_section = current_section + 1;

        if( !await GameService.update( {id, current_section } ) ){
            return notify( GameService.error || "Unknown error")
        }

        // update parent 
        onUpdate();

    }

    addTeam = async (team) => {
        const {id, onUpdate} = this.props;
        
        if( ! await GameService.addTeam( id, team.id )){
            return notify( GameService.error || "Unknown error")
        }

        onUpdate();
    }

    removeTeam = async (team) => {
        const {id, onUpdate } = this.props;
        
        if( ! await GameService.removeTeam( id, team.id) ){
            return notify( GameService.error || "Unknown error")
        }

        onUpdate();
    }

    addSection = async (data) => {
        const {id, onUpdate} = this.props;
        
        if( ! await GameService.addSection( id, data )){
            return notify( GameService.error || "Unknown error")
        }

        onUpdate();
    }

    updateSection = async (data) => {
        const {id, onUpdate} = this.props;
        
        if( ! await GameService.updateSection( id, data )){
            return notify( GameService.error || "Unknown error")
        }
        this.setState({textInputProps: null})
        onUpdate();
    }

    removeSection = async (section_order) => {
        const {id, onUpdate } = this.props;
        
        if( ! await GameService.deleteSection( id, section_order) ){
            return notify( GameService.error || "Unknown error")
        }
        this.closeConfirmDialog();

        onUpdate();
    }

    closeConfirmDialog = () => {
        this.setState({onConfirm: null, confirmPrompt: null, confirmTitle: null})
    }

    showConfirmDialog = ( confirmPrompt, onConfirm, confirmTitle ) => {
        this.setState({confirmPrompt, onConfirm, confirmTitle})
    }


    render(){
        let {id, title, sections, current_section, started_at, finished_at, teams, questions, section_id, onUpdate} = this.props;
        section_id = section_id ? parseInt( section_id ) : section_id;

        const {teamList, showSelectTeam, confirmPrompt, confirmTitle, onConfirm, textInputProps} = this.state;

        console.debug( "team stats:", teamList )
        console.debug( "showSelectTeam:", showSelectTeam )

        const gameIsStarted = Boolean(started_at);
        const gameIsFinished = Boolean(finished_at);


        sections = Array.isArray(sections) ? sections.sort( (a,b) => a.order < b.order ) : sections;


        const section = section_id >= 0 ? sections.find( s => s.order === section_id ) : null;

        console.debug("game propeties section_id:", section_id, "section:", section)

        if( section_id >=0  && section ) return <GameSectionContent {...section} game={this.props} onUpdate={onUpdate} />

        const canStart = !gameIsStarted && Array.isArray(sections) && sections.length > 0 
        && Array.isArray(questions) && questions.length > 0
        && Array.isArray(teams) && teams.length > 0

        return(<Container>
                <Typography variant="h1">№{id}: {title}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <div className="flex row">
                            {!gameIsStarted && sections?.length > 0 && <Button variant="contained" color="primary" onClick={this.startGame} disabled={!canStart} >Начать</Button>}
                            {gameIsFinished && <Alert severity="info" className="m-top-10 m-bottom-10">Игра закончена</Alert> }
                        </div>
                        <Typography variant="h2">Фазы</Typography>
                        <div className="flex row">
                            {!gameIsStarted && <Button variant="contained" color="primary" size="small" onClick={() => this.addSection({})} ><Add/></Button>}
                        </div>
                        {!gameIsStarted && Array.isArray(sections) &&  (
                            <table className="striped" style={{width: "100%"}}>
                            <thead>
                                <tr>
                                    <th>#</th>                                    
                                    <th>мин.</th>                                    
                                    <th>данные</th>                                    
                                    <th>Очки</th>                                    
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sections.sort( (a,b) => a.order - b.order ) .map( (s) => <tr key={`section-${s.order}`}>
                                    <td>{s.order + 1}</td>                                    
                                    <td align='right'>{s.time}<IconButton size="small" onClick={() => this.setState({
                                            textInputProps: {
                                                open: true,
                                                header: `Время (мин) фазы ${s.order + 1}`,
                                                value: s.time,
                                                onClose: () => this.setState({textInputProps: null}),
                                                onConfirm: (value) => this.updateSection( {...s, time: value} ),
                                                inputProps: {
                                                    type: "number"
                                                }
                                            }
                                        })}><Edit/></IconButton></td>                                                                            
                                    <td align='center'>{ s.content?.length || 0 }
                                        <IconButton size="small" component={Link} to={`/games/${id}/section/${s.order}/content`} ><Edit/></IconButton>
                                    </td>
                                    <td align='center'>{ s.score || 0 }
                                        <IconButton size="small" onClick={() => this.setState({
                                            textInputProps: {
                                                open: true,
                                                header: `Очки фазы ${s.order + 1}`,
                                                value: s.score || 0,
                                                onClose: () => this.setState({textInputProps: null}),
                                                onConfirm: (value) => this.updateSection( {...s, score: value} ),
                                                inputProps: {
                                                    type: "number"
                                                }
                                            }
                                        })}><Edit/></IconButton>
                                    </td>
                                    <td>
                                        <IconButton size="small" onClick={() => this.showConfirmDialog(
                                            `Удалить фазу ${s.order + 1}? Все данные этой фазы также будут удалены.`,
                                            () => this.removeSection(s.order)
                                        )
                                            } ><Delete/></IconButton>                                        
                                    </td>
                                </tr> )}                                                                                                                                                                                                                                                                                                                                                                                                                                       
                            </tbody>
                        </table>
                        )}
                        { gameIsStarted && Array.isArray(sections) && 
                            (<table className="striped" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>очки</th>
                                        <th>время</th>
                                        <th>нач.</th>
                                        <th>законч.</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sections.sort( (a,b) => a.order - b.order ) .map( (s) => <tr key={`section-${s.order}`}>
                                        <td>{s.order + 1}</td>
                                        <td>{s.score}</td>                                        
                                        <td>{s.time}</td>                                        
                                        <td>{s.started_at}</td>
                                        <td>{s.finished_at}</td>
                                        <td>
                                            { s.order === current_section && (
                                                <>
                                                    { !s.started_at && <Button variant="contained" color="primary" size="small" onClick={this.startSection}>Start</Button> }
                                                    { (s.started_at && !s.finished_at) && <Button size="small" onClick={this.finishSection}>Finish</Button> }
                                                    { (s.started_at || s.finished_at) && <Button size="small" onClick={this.resetSection}>Reset</Button> }                                                    
                                                    { (s.started_at && s.finished_at) && <Button size="small" onClick={this.openNext}>Next</Button> }                                                    
                                                </>    
                                            )}
                                        </td>
                                    </tr> )}                                                                                                                                                                                                                                                                                                                                                                                                                                       
                                </tbody>
                            </table>)
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="h2">Команды</Typography>
                        {!gameIsStarted && <Button color="primary" variant="contained" size="small" onClick={()=> this.setState({showSelectTeam: true})}><Add/></Button>}
                        
                            { teamList === null && <MenuItem><CircularProgress/></MenuItem>}
                            { Array.isArray(teamList) && teamList.length === 0 && <MenuItem>No teams found</MenuItem>}
                            { !gameIsStarted && Array.isArray(teamList) && teamList.length > 0 && (
                                <>
                                    <table className="striped" style={{width: "100%"}}>
                                        <thead>
                                            <tr>
                                                <th>Название</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {teamList.map( t => (<tr key={`team-${t.id}`}>
                                                <td>{t.title}</td>                                            
                                                <td><IconButton size="small" onClick={()=> this.removeTeam(t)}><Delete/></IconButton></td>
                                            </tr>))}
                                        </tbody>
                                    </table>                                    
                                </>
                                // teamList.map( t => <MenuItem key={`team-${t.id}`} className="flex row content-space-between">{t.title}<IconButton color="secondary"><Delete/></IconButton></MenuItem>)
                            )}
                            { gameIsStarted && Array.isArray(teamList) && teamList.length > 0 && (
                                <>                                
                                <table className="striped" style={{width: "100%"}}>
                                    <thead>
                                        <tr>
                                            <th>Название</th>
                                            <th>Ответы</th>
                                            <th>Прав.</th>
                                            <th>Очки</th>
                                            <th>См.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teamList.map( t => (<tr>
                                            <td>{t.title}</td>
                                            <td>{t.answers.length}</td>
                                            <td>{t.correct_answers.length}</td>
                                            <td>{t.game_score}</td>
                                            <td><IconButton variant="contained" size="small"><Visibility/> </IconButton></td>
                                        </tr>))}
                                    </tbody>
                                </table>                                
                                </>
                            )}
                            <SelectTeam open={showSelectTeam} onClose={()=> this.setState({showSelectTeam: false})} onSelect={this.addTeam} alreadySelected={teams} />                                
                            <ConfirmDialog open={Boolean(confirmPrompt)} header={confirmTitle} prompt={confirmPrompt} onConfirm={onConfirm} onClose={this.closeConfirmDialog} />
                            { textInputProps && <TextInputDialog {...textInputProps} />}
                    </Grid>
                    
                    <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="h2">Вопросы</Typography>
                        <GameQuestions  {...{game_id: id, questions, onUpdate}} />
                    </Grid>
                </Grid>
            </Container>)
    }
}

export default GameProperties