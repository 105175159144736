import React from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Email,
  Lock,
  AccountCircle as UserIcon,
  PersonAddRounded as AddUser,
} from '@material-ui/icons';
import store from '../../redux/store';
import A from '../../redux/actionTypes';
import Service from '../../services/AuthService';

import './login.scss';

class Register extends React.Component {
  state = {
    name: '',
    email: '',
    password: '',
    connecting: false,
  };

  validateForm = (ev) => {
    return ev.currentTarget.reportValidity();
  };

  register = async (ev) => {
    // catch submit
    ev.preventDefault();

    if (this.validateForm(ev)) {
      const { name, email, password } = this.state;

      if (email && password) {
        console.debug('Registering...');
        this.setState({ connecting: true }, () => {
          this.setState({ connecting: false });
          this.doRegister({ name, email, password });
        });
      }
    }
  };

  doRegister = async (data) => {
    const result = await Service.register(data);

    if (result) {
      store.dispatch({
        type: A.SHOW_MESSAGE,
        payload: {
          message:
            'User successfully registred. You can now login with your email/password',
          type: 'info',
        },
      });
    } else {
      console.error('Error logging in: ', result);
    }
  };

  updateValue = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  render() {
    const { name, email, password, connecting } = this.state;

    const {
      RegistrationText = "Registration",
      FullNameText = "Your full name",      
    } = this.props;

    return connecting ? (
      <Container>
        <CircularProgress /> Registering new user...{' '}
      </Container>
    ) : (
      <Container id='register'>
        <form noValidate className='login-form' onSubmit={this.register}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <AddUser fontSize='large' color='primary' />
              <br />
              <Typography variant='h6' color='primary'>
                {RegistrationText}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor='input-with-icon-adornment'>
                  {FullNameText}
                </InputLabel>
                <Input
                  id='input-with-icon-adornment'
                  autoComplete='name'
                  type='text'
                  name='name'
                  onChange={this.updateValue}
                  value={name}
                  startAdornment={
                    <InputAdornment position='start'>
                      <UserIcon color='primary' />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor='input-with-icon-adornment'>
                  Email
                </InputLabel>
                <Input
                  id='input-with-icon-adornment'
                  autoComplete='username'
                  type='email'
                  name='email'
                  onChange={this.updateValue}
                  value={email}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Email color='primary' />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor='input-with-icon-adornment2'>
                  Password
                </InputLabel>
                <Input
                  id='input-with-icon-adornment2'
                  type='password'
                  name='password'
                  autoComplete='current-password'
                  onChange={this.updateValue}
                  value={password}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Lock color='primary' />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' type='submit'>
                Register
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

export default Register;
