import React from 'react'
import './notification.scss';
import { Snackbar, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab';

/**
 * Shows notification snackbars at the bottom of the page.
 * props:
 *    messages = [],
 *    maxMessagesToShow = 4  - number of messages to show on screen
 *    deleteMessage - callback that receives index of the message in stack and removes it
 *    clearMessages - callback that removes all messages 
 *    hideText = "Hide" - text for 'hide messages' button
 *    showText = "Show" - text for 'show messages' button
 *    clearText = "Clear all" - text for 'clear' button 
 *    NmessagesText = "%n% messages" - text for quantity indicator. %n% will be replaced with number of messages.
 */
class Notification extends React.Component {

    state = {
        hidden: false
    }

    hideMessages() { this.setState({ hidden: true }) }
    showMessages() { this.setState({ hidden: false }) }

    render() {
        const { hidden } = this.state
        const {
            messages = [],
            maxMessagesToShow = 4,
            deleteMessage = id => console.debug("Should close message", id),
            clearMessages = () => console.debug("Should clear all messages"),
            hideText = "Hide",
            showText = "Show",
            clearText = "Clear all",
            NmessagesText = "%n% messages"
        } = this.props;

        if (!Array.isArray(messages)) return null;

        const length = messages.length;

        return <div className="notification-container">
            {!hidden && messages.map((m, ind) => ind < maxMessagesToShow &&
                // <Snackbar open={true} onClose={() => this.closeHandler(ind)}>
                <Snackbar open={true}>
                    <Alert
                        elevation={6}
                        variant='filled'
                        severity={m.type}
                        onClose={() => deleteMessage(ind)}
                    >
                        {m.message}
                    </Alert>
                </Snackbar>
            )}
            {length > 0 && <div className="clear-button-container">
                { length > maxMessagesToShow && <span className="quantity">{NmessagesText.replace("%n%", length)}</span>}
                {length > maxMessagesToShow && !hidden && <Button variant="contained" onClick={this.hideMessages.bind(this)}>{hideText}</Button>}
                {length > maxMessagesToShow && hidden && <Button variant="contained" onClick={this.showMessages.bind(this)}>{showText}</Button>}
                {length > 1 && <Button variant="contained" color="secondary" onClick={clearMessages}>{clearText}</Button> }
            </div>}
        </div>
    }
}

export default Notification;