
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import MenuBlock from '../menu-block';

const LinkItem = ({to, title, _children}) =><>
    <Link to={to}>{title}</Link>
    {_children && <MenuBlock items={_children} />}
</> 

export default LinkItem