import ActionTypes from './actionTypes';

export const showMessage = (message, type) => (dispatch) =>
  dispatch({
    type: ActionTypes.SHOW_MESSAGE,
    payload: { message, type },
  });

export const deleteMessage = (id) => {
  return (dispatch) => dispatch({ type: ActionTypes.DELETE_MESSAGE, payload: id });
};

export const clearMessages = () => {
  console.debug("dispatching clear messages")
  return (dispatch) => dispatch({ type: ActionTypes.CLEAR_MESSAGES });
};