import React from 'react';
// import AuthWrap from '../Auth';
import { BrowserRouter as Router } from 'react-router-dom';
// import Footer from '../template/footer';
import Navbar from '../template/Navbar';
import Routes from '../Routes';
import routes from '../../data/secured_routes';
import { arraysIntersect } from '../../utils/functions';
import { Fab } from '@material-ui/core';

class SecuredMain extends React.Component {

  state = {
    filteredRoutes: []
  }

  componentDidMount(){
    const {user} = this.props;

    if( !Array.isArray(user.credentials)){
      return this.setState({filteredRoutes: []});
    }

    const filteredRoutes = routes.filter( r => !r.credentials || arraysIntersect(r.credentials, user.credentials) )
    this.setState({filteredRoutes})
  }

  componentDidUpdate(prevProps){
    const {user} = this.props;
    console.debug("updating secured routes", JSON.stringify(prevProps.user?.credentials),JSON.stringify( user.credentials))
    // TODO: check if this comparison works
    if( prevProps.user !== user || JSON.stringify(prevProps.user?.credentials) !== JSON.stringify( user.credentials) ){
      console.debug("filtering routes by", user.credentials)
      const filteredRoutes = routes.filter( r => !r.credentials || arraysIntersect(r.credentials, user.credentials) )
      console.debug( "filtered routes:", filteredRoutes)
      this.setState({filteredRoutes})
    }
  }

  render() {
    const { location, user, navbarProps = {} } = this.props;
    const {filteredRoutes} = this.state;
    console.debug("user in SecuredMain", user)
    
    if( !filteredRoutes || filteredRoutes?.length === 0) return <>...</>

    return (
      <Router>
        <Navbar {...{...navbarProps, location, user}}/>
        <div className='main'>
          <Routes routes={filteredRoutes} user={user} id='main' />
        </div>
        {/* <ReduxModal /> */}
        {/* <Notification /> */}
        {/* <Footer /> */}        
      </Router>
    );
  }
}

export default SecuredMain;
