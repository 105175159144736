import React from 'react';
import { connect } from 'react-redux';
import './App.scss';
import Main from './components/Main'
import Notification from './components/template/Notification';
import { clearMessages, deleteMessage } from './redux/notificationActions';
import {securedAppProps, nonSecuredAppProps, notificationProps} from './data/config';
import OptionsDrawer from './components/template/options-drawer';

function App(props: any) {
  const {deleteMessage, clearMessages, messages } = props;
  return <> 
    <Main  {...{securedAppProps, nonSecuredAppProps}} />
    <Notification {...{
      deleteMessage, 
      clearMessages, 
      messages,
      ...notificationProps
    }} />
    <OptionsDrawer/>
  </>
  
}

const mapStateToProps = (store: any) => (store.ui)
const mapDispatchToProps = (dispatch: any) => ({
    deleteMessage: (id: any) => dispatch(deleteMessage(id)),
    clearMessages: () => dispatch( clearMessages() )
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
