import React from 'react';
import { Switch } from 'react-router-dom';
// import Route from '../template/scrollToTop'; // route with auto-scroll to top on navigation
import {Route} from 'react-router-dom';

const RoutesComponent = (props) => {
  const { routes = [], user } = props;

  return (
    <Switch>
      {
      routes.map((route, ind) => 
        {
            const {component : Component, ...other} = route;
            return <Route {...{ ...other, key: `route-${ind}` }} component={(props)=> <Component {...{...props, user}} /> } />
        }
      )}
    </Switch>
  );
};

export default RoutesComponent;
