import BaseClientService from  "./BaseClientService"
import store from "../redux/store";
import { saveContent } from "../redux/dbActions";
import { arraysIntersect } from "../utils/functions";

class ContentService extends BaseClientService {

    contentList = null

    static async getAll(){
        // if( !this.teamList ){
        //     this.teamList = [...teams];
        // }
        this.contentList = store.getState().db.content;

        const t = this.contentList;
        console.debug("content:", t)
        
        return t;
    }

    static async getOne( id ){
        return (await this.getAll()).find( u => u.id === id)
    }

    /**
     * Filter by ids
     * @param {array} ids 
     * @returns 
     */
    static async getByIds( ids ){
        const list = await this.getAll();

        return list.filter( t => ids.includes(t.id))
    }

    static async add( data ){
        const {title} = data;

        const id = await this.getNextId();
        // add element
        this.contentList = [ ... await this.getAll(), 
            {
                id, title
            }
        ]

        // update in state
        saveContent(this.contentList);

        return id;
    }

    static async update( data ){
        const {id, ...other} = data;

        if(!id) return this.Error("empty id");
        const list = await this.getAll();
        const itemIndex = list.findIndex( t => t.id === id );
        if( !itemIndex ) return this.Error(`Content item with id [${id}] was not found`)

        this.contentList[itemIndex] = {...list[itemIndex], ...other}

        // update in state
        saveContent(this.contentList);
        return true

    }

    /**
     * removes multiple items by given ids
     */
    static async removeContent( ...ids){
        
        this.contentList = this.contentList.filter( i => !ids.includes(i.id));

        // update in state
        saveContent(this.contentList);
        return true;
    }

    static async delete(id){
        const itemIndex = (await this.getAll()).findIndex( t => t.id === id );

        if( !(itemIndex >=0 ) ) return this.Error(`Content item with id [${id}] was not found`)

        // remove
        this.contentList.splice(itemIndex, 1);

        // update in state
        saveContent(this.contentList);
        return true;
    }

    static async validateUser( email, pass, credentials = ["admin", "captain"] ){
        const all = await this.getAll();
        return all.find( u => u.email === email && u.password === pass && arraysIntersect(credentials, u.credentials) );
    }

    static async getNextId(){
        const all = await this.getAll();

        return Math.max( ...all.map( t => t.id) ) + 1;
    }
}

ContentService.defaultContent =  {
    "id": null,
    "type": "html",
    "title": "",
    "content": ""
  }

BaseClientService.extend(ContentService);

export default ContentService;