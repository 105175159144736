import A from './actionTypes';
const localStorageKey = process.env.REACT_STORAGE_KEY || 'FloreximApp';

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{
    user: null,
    authorization: null
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

export default function root(state = initialState, action) {
  let newState = { ...state };
  const {type, payload} = action;
  console.debug("authReducers:", type, payload)

  switch (type) {
    case A.LOGIN:
      const { token, user } = payload;
      // put token into state as authorization header value
      newState = { ...state, user, authorization: `Bearer ${token}` };
      break;

      // replace user
    case A.REPLACE:{
      const {token} = newState.user;
      // put token into state as authorization header value
      newState = { ...state, user: payload, authorization: `Bearer ${token}` };
    }      
      break;

    case A.LOGOUT:
      newState = { ...state, authorization: null, user: null };
      break;

    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}
