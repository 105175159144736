import React from 'react'
import GameService from '../../services/game-service'
import { Card, Container, List, MenuItem, Typography } from '@material-ui/core'

interface Content {
    id: number | null,
    title: string,
    type: string,
    content: string
}

interface IGCCProps{
    content: Content[]
}


class GameCurrentContent extends React.Component<IGCCProps>{
    render(){
        const {content} = this.props;
        return(<Container>            
            
                {Array.isArray(content) && content.map( c => (                
                    <Card className="m-top-10 m-bottom-10 p-10">
                    <Typography variant="h3">{c.title}</Typography>                    
                    <div dangerouslySetInnerHTML={{__html: c.content}}></div>
                    </Card>
                
                ))}
            
        </Container>)
    }
}

export default GameCurrentContent