import React from 'react'
import ModalDialog from '../modal-dialog'
import { Button, TextField } from '@material-ui/core';


const defaultOkButtonProps = {
    color: "primary",
    variant: "contained"
}
const defaultCancelButtonProps = {
    color: "secondary",
    variant: "contained"
}
/**
 * Component that renders a modal window with single textfield and Ok/Cancel buttons
 * returns the textfield value as parameter of onConfirm callback prop
 */
class TextInputDialog extends React.Component{

    state = {
        value: ""
    }

    componentDidMount(){
        const {value = ""} = this.props;        
        this.setState({value})
    }

    componentDidUpdate(prevProps){
        const {value} = this.props;        
        if( prevProps.value !== value ){
            this.setState({value})
        }
    }

    render(){
        const { 
            value: propsValue, 
            inputProps = {}, 
            onConfirm = console.debug,
            onClose,
            okText = "Ok",
            cancelText = "Cancel",            
            okButtonProps = defaultOkButtonProps,
            cancelButtonProps = defaultCancelButtonProps,
            allowEmpty = false,
        ...modalProps} = this.props;

        const {value} = this.state;

        return(

            <ModalDialog {...modalProps} onClose={onClose}>
                <TextField 
                    value={value}
                    {...inputProps}
                    onChange={({target})=> this.setState({value: target.value})}
                />
                <div className="flex row content-space-between">
                    <Button {...okButtonProps} onClick={() => onConfirm(value)} disabled={ !value && !allowEmpty}>{okText}</Button>
                    <Button {...cancelButtonProps} onClick={onClose}>{cancelText}</Button>
                </div>
            </ModalDialog>
        )
    }
}

export default TextInputDialog