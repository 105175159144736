import BaseClientService from  "./BaseClientService"
import { saveGames } from "../redux/dbActions";
import store from "../redux/store";
import TeamService from "./team-service";
import { arraysIntersect } from "../utils/functions";
import GameProgressService from "./game-progress-service";
import ContentService from "./content-service";

class GameService extends BaseClientService {

    gameList = null

    static async getAll(){
        if( !this.gameList ){
            // this.gameList = [...games];
            this.gameList = store.getState().db.games;
        
        }
        const t = this.gameList;
        console.debug("games:", t)
        
        return t;
    }

    static async getOne( id ){
        return (await this.getAll()).find( g => g.id === id)
    }

    static async add( data ){

        const id = await this.getNextId();
        // add element
        this.gameList = [ ... await this.getAll(), 
            {...this.defaultGame, ...data, id}
        ]

        // update state
        saveGames(this.gameList);

        return id;
    }

    static async update( data ){
        const {id, ...other} = data;

        if(!id) return this.Error("empty id");
        const list = await this.getAll();
        const itemIndex = list.findIndex( t => t.id === id );
        if( !(itemIndex >= 0) ) return this.Error(`Item with id [${id}] was not found`)

        this.gameList[itemIndex] = {...list[itemIndex], ...other}

        // update state
        saveGames(this.gameList);

        return true

    }

    static async delete(id){
        id = parseInt(id);
        const itemIndex = (await this.getAll()).findIndex( t => t.id === id );

        if( !(itemIndex >= 0) ) return this.Error(`Item with id [${id}] was not found`)

        // remove
        this.gameList.splice(itemIndex, 1);

        // update state
        saveGames(this.gameList);

        return true;
    }

    static async getUserGames( user_id){


        let userTeams = await TeamService.getUserTeams( user_id);

        const userTeamsIds = userTeams.map( t => t.id );
        const all = await this.getAll();
        console.debug( "userteams:", userTeamsIds)
        let result = all.filter( g => arraysIntersect( userTeamsIds, g.teams ) )
        // get user team as the first team within user's teams that's in current game         
        result = result.map( g => ({...g, team: userTeams.find( t => g.teams.includes( t.id ))}))

        // get team answers for each game
        for( let i=0; i<result.length; i++){
            result[i].answers = await GameProgressService.getTeamAnswers(  result[i].team.id, result[i].id)
        }

        return result

    }

    /**
     * Returns all available content for current game state (content from all sections up to current one)
     */
    static async getCurrentGameContent( game_id ){
        const game = await this.getOne( game_id );
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }
        if( !game.started_at ){             
            return [];
        }

        const {sections} = game;

        console.debug("getCurrentGameContent sections:", sections)

        // collect content ids from all started sections
        const content_ids = sections.filter( s => s.started_at).reduce( (res, s) => ([...res, ...s.content ]), []);

        if( content_ids.length === 0 ) return [];

        return ContentService.getByIds(content_ids);

    }
    
    static async getGameStats( game_id){
        let game = await this.getOne( game_id );
        console.debug("game team ids:", game.teams)
        let teams = await TeamService.getByIds(game.teams)
        console.debug("game teams:", teams)
        for( let i=0; i<teams.length; i++){
            let answers = await GameProgressService.getTeamAnswers(  teams[i].id, game_id)
            
            let correct_answers = answers.filter( a => game.questions.find( q => q.id === a.question_id ) ?.correctAnswer === a.answer_id  )
            let game_score = correct_answers.reduce( (res, a) => parseInt(res) + parseInt(a.score), 0)

            teams[i] = {...teams[i], answers, correct_answers, game_score }
        }

        return teams.sort((a,b) => b.game_score - a.game_score );
    }

    static async addSection( game_id, data){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Команда не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя добавить фазу" );
        }

        let {sections} = game;
        // just in case...
        sections = Array.isArray(sections) ? sections : [];


        const newSection = {...this.defaultSection,  
            // overwrite with only relevant keys from the data
            // ...Object.keys( this.defaultSection ).reduce( (res, key) => ({...res, [key] : data[key]}), {} )  
        }  
        newSection.order = sections.length;
        
        return await this.update({ ...game, sections: [...sections, {...newSection, ...data} ]})
    }

    static async updateSection( game_id, data){
        
        if( !(data.order >=0 ) ){
            return this.Error("Не передан номер фазы!" );
        }
        

        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя добавить фазу" );
        }

        let {sections} = game;
        // just in case...
        sections = Array.isArray(sections) ? sections : [];

        let sectionIndex = sections.findIndex( s => s.order === data.order ) 
        console.debug("finding", data.order, "in", sections.map(s=> s.order), ", found:", sectionIndex);
        if( !(sectionIndex >=0 ) ){
            return this.Error("Фаза не найдена!" );
        }

        const section = sections[sectionIndex];

        // get only relevant keys from the data
        const newSection =   { ...section, 
            // all relevant fields from data
            ...Object.keys( this.defaultSection ).reduce( (res, key) => ({...res, [key] : data[key] }), {} )
        }

        // update record in sections array
        sections[sectionIndex] = newSection;
        
        return await this.update({ ...game, sections})
    }

    static async deleteSection( game_id, order, delete_content = true ){
        console.debug("deleteSection called with", game_id, order )
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя удалить фазу" );
        }

        let {sections} = game;
        // just in case...
        sections = Array.isArray(sections) ? sections : [];

        const index = sections.findIndex( s => s.order === order );
        if( !(index >= 0) ) return this.Error("Секция не найдена")

        console.debug("deleting section with index", index, "from", sections);

        // TODO: delete content if asked to
        if( delete_content){
           // await ContentService.removeContent( ...sections[index].content);
        }
        // remove section
        sections.splice( index, 1)
        sections = await this.renumberSections(sections);

        console.debug("sections after deleting section:", sections)
        
        
        return await this.update({ ...game, sections: [...sections]})
    }

    static async renumberSections( sections ){        
        // make section's place in array its order
        return sections.map( (s, order)=> ({...s, order }))
    }

    static async addTeam( game_id, team_id){
        const game = await this.getOne(game_id);
        if( !game){ 
            console.error(`Game with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }


        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить состав" );
        }

        let {teams} = game;
        // just in case...
        teams = Array.isArray(teams) ? teams : [];

        // check if team already in the list
        if( teams.includes(team_id)) {
            return this.Error("Команда уже в игре");
        }

        return await this.update({ ...game, teams: [...teams, team_id]})
    }

    static async removeTeam( game_id, team_id){
        console.debug("removeTeam called with", game_id, team_id)
        const game = await this.getOne(game_id);
        if( !game){ 
            console.error(`Game with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить состав" );
        }

        let {teams} = game;
        // just in case...
        teams = Array.isArray(teams) ? teams : [];


        // check if team is in the list
        if( !teams.includes(team_id)) {
            return this.Error("Команды нет в игре");
        }

        // filter team out 
        console.debug("game teams before removing", teams)
        teams = teams.filter( t => t !== team_id )
        console.debug("game teams after removing", teams)
        

        return await this.update({ ...game, teams})
    }


    static async addContentToSection( game_id, section_id, data){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить данные" );
        }

        let {sections} = game;
        // just in case...
        sections = Array.isArray(sections) ? sections : [];

        const sectionIndex = 
            sections.findIndex( s => s.order === section_id );
        
        if( !(sectionIndex >=0 ) ){
                return this.Error("Фаза не найдена" );    
            }
        const section = sections[sectionIndex]
        
        

        let {content} = section

        content = Array.isArray(content) ? content : [];

        const newContentId = await ContentService.add(data)
        
        // something happened - return error
        if( !newContentId ) return this.Error( ContentService.error )

        // update section
        sections[sectionIndex] = {...section, content: [...content, newContentId] }
        
        // update game
        return await this.update({ ...game, sections })
    }

    static async updateSectionContent( game_id, section_id, content_id, data){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить данные" );
        }

        let {sections} = game;
        // just in case...
        sections = Array.isArray(sections) ? sections : [];

        const sectionIndex = 
            sections.findIndex( s => s.order === section_id );
        
        if( !(sectionIndex >=0 ) ){
                return this.Error("Фаза не найдена" );    
            }
        const section = sections[sectionIndex]
        
        

        let {content} = section

        content = Array.isArray(content) ? content : [];

        if( !content.includes(content_id)){
            return this.Error("Материал не найден в данной фазе" );   
        }

        // update content
        return await ContentService.update({...data, id: content_id})
    }

    static async deleteSectionContent( game_id, section_id, content_id ){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить данные" );
        }

        let {sections} = game;
        // just in case...
        sections = Array.isArray(sections) ? sections : [];

        const sectionIndex = 
            sections.findIndex( s => s.order === section_id );
        
        if( !(sectionIndex >=0 ) ){
                return this.Error("Фаза не найдена" );    
            }
        const section = sections[sectionIndex]

        let {content} = section

        content = Array.isArray(content) ? content : [];

        if( !content.includes(content_id)){
            return this.Error("Материал не найден в данной фазе" );   
        }

        // delete content
        return await ContentService.delete(content_id)
    }

    static async addQuestion( game_id, data){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить данные" );
        }

        let {questions} = game;
        // just in case...
        questions = Array.isArray(questions) ? questions : [];

        // create new id
        const id = Math.max( ...questions.map( q => q.id), 0 ) + 1;

        questions = [...questions, {...this.defaultQuestion, ...data, id}]
        
        // update game
        return await this.update({ ...game, questions })
    }

    static async updateQuestion( game_id, data){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить данные" );
        }

        let {questions} = game;
        // just in case...
        questions = Array.isArray(questions) ? questions : [];

        const {id} = data;
        if( !(id >= 0) ) return this.Error("Вопрос не найден" );
        
        const questionIndex = questions.findIndex ( q => q.id === id);
        if( !(questionIndex >= 0) ) return this.Error("Вопрос не найден" );
        
        questions[questionIndex] = {...questions[questionIndex], ...data};

        // update game
        return await this.update({ ...game, questions })
    }

    static async deleteQuestion( game_id, id){
        const game = await this.getOne(game_id);
        
        if( !game){ 
            console.error(`command with id=[${game_id}] not found`)
            return this.Error("Игра не найдена");
        }

        if( game.started_at ){
            return this.Error("Игра уже начата, нельзя изменить данные" );
        }

        let {questions} = game;
        // just in case...
        questions = Array.isArray(questions) ? questions : [];

        
        if( !(id >= 0) ) return this.Error("Вопрос не найден" );
        
        const questionIndex = questions.findIndex ( q => q.id === id);
        if( !(questionIndex >= 0) ) return this.Error("Вопрос не найден" );
        
        questions.splice(questionIndex, 1);

        // update game
        return await this.update({ ...game, questions });
    }

    static async getNextId(){
        // find maximum existing id and add 1
        // return Math.max( ... this.gameList.map( t => t.id) ) + 1;
        const all = await this.getAll();
        return Math.max( ...all.map( t => t.id), 0 ) + 1;
    }
}

BaseClientService.extend(GameService);

GameService.defaultGame = {
    "id": null,
    "title": "",
    "teams": [],
    "date": "",
    "sections": []
  }

GameService.defaultSection = {
    order: null,
    time: 1,
    started_at: null,
    finished_at: null,
    score: 0,
    content: []
}
GameService.defaultQuestion = {
    "id": null,
    "type": "quiz",
    "question": "",
    "correctAnswer": null,
    "answers": []
  }
GameService.defaultAnswer =  { "id": null, "text": "" }
  



export default GameService;