import BaseClientService from  "./BaseClientService"
import store from "../redux/store";
import { saveTeams } from "../redux/dbActions";
import UserService from "./user-service";


const defaultTeam = {
    id: null, 
    title: "",
    score: 0,
    rating: "Novice"
}

class TeamService extends BaseClientService {

    teamList = null

    static async getAll(){
        if( !this.teamList ){
            // this.teamList = [...teams];
            this.teamList = store.getState().db.teams;
        }
        const t = this.teamList;
        console.debug("teams:", t)
        
        return t;
    }

    static async getOne( id ){
        return (await this.getAll()).find( t => t.id === id)
    }

    /**
     * Filter by ids
     * @param {array} ids 
     * @returns 
     */
    static async getByIds( ids ){
        const list = await this.getAll();

        return list.filter( t => ids.includes(t.id))
    }

    static async add( data ){
        console.debug("adding new team:", data)        
        const id = await this.getNextId();
        // add element
        this.teamList = [ ... await this.getAll(), 
            {
                ...data, id
            }        ]
        // update state
        saveTeams(this.teamList);
        return id;
    }

    static async update( data ){
        const {id, ...other} = data;

        if(!id) return this.Error("empty id");
        const list = await this.getAll();
        const itemIndex = list.findIndex( t => t.id === id );
        if( !(itemIndex >= 0) ) return this.Error(`Item with id [${id}] was not found`)

        this.teamList[itemIndex] = {...list[itemIndex], ...other}

        // update state
        saveTeams(this.teamList);
        return true

    }

    static async delete(id){
        const itemIndex = (await this.getAll()).findIndex( t => t.id === id );

        if( !(itemIndex >=0 ) ) return this.Error(`Item with id [${id}] was not found`)

        // remove
        this.teamList.splice(itemIndex, 1);

        // update state
        saveTeams(this.teamList);
        return true;
    }


    static async getUserTeams( user_id){
        const all = await this.getAll();

        return all.filter ( t => t.captain === user_id )
    }

    static async getTeamCaptain( team_id ){
        const team = await this.getOne(team_id)
        if( !team) return this.Error("Команда не найдена")
        if( !team.captain ) return null

        return await UserService.getOne( team.captain )
    }
    
    static async assignCaptain( team_id, captain ){
        const team = await this.getOne(team_id)
        if( !team) return this.Error("Команда не найдена")
        // tODO: check if user exists
        
        return await this.update( { ...team, captain} )
    }

    static async removeCaptain( team_id ){
        const team = await this.getOne(team_id)
        if( !team) return this.Error("Команда не найдена")
        
        return await this.update( { ...team, captain: null} )
    }

    // static async getByIds(...ids){
    //     const all = await this.getAll();

    //     return all.filter ( t => ids.includes(t.id) )
    // }


    static async getNextId(){
        const all = await this.getAll();
        return Math.max( ...all.map( t => t.id), 0 ) + 1;
    }
}

TeamService.defaultTeam = defaultTeam;

BaseClientService.extend(TeamService);

export default TeamService;