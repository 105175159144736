import React from 'react'
import ModalDialog from '../template/modal-dialog'
import { List, MenuItem, IconButton, Button, Typography } from '@material-ui/core';
import { Answer, Question } from './types';
import { Check, Close, Add, Delete, Edit } from '@material-ui/icons';
import { notify } from '../../redux/reduxFunctions';
import TextInputDialog from '../template/text-input-dialog';



export interface IModalDialog{
    open: boolean
    header ?: string,
    onClose : () => void,
}

interface ITextInputProps extends IModalDialog {    
    onConfirm: ( text: string ) => void,
    value ?: string
}

export interface GQAState {
    question : Question,
    textInputProps: ITextInputProps | null
}

export interface GQAProps extends IModalDialog{
    question: Question,        
    onUpdate: (q: Question) => void    
}

class GameQuestionAnswers extends React.Component<GQAProps, GQAState>{
    
    constructor(props: GQAProps ) {
        super(props);
        
        this.state = {
            question : {
                id: null,
                type: "quiz",
                question : "",
                correctAnswer: null,
                answers: []
            },
            textInputProps: null
        };
      }

    componentDidMount(){
        const {question} = this.props;
        this.setState({question});
    }


    componentDidUpdate(prevProps : GQAProps){
        
        if( prevProps.question !== this.props.question ){
            this.setState({question: this.props.question})
        }
    }

    updateAnswer = (answer: Answer ) => {
        const {question} = this.state;
        if( answer.id === null ){
            // add a new answer            
            const newAnswer : Answer = {...answer, id: this.getNextId() };

            question.answers = [...question.answers, newAnswer];            

            // if it's the first answer - make it also correct by default
            if( newAnswer.id === 1) question.correctAnswer = newAnswer.id;

            return this.setState({question, textInputProps: null});
        }

        // find existing answer index
        const answerIndex = question.answers.findIndex( a => a.id === answer.id );

        // check if exists
        if( !(answerIndex>=0) ) {
            return notify("Ответ с таким ID не найден");
        }

        // update existsing Answer
        question.answers[answerIndex] = answer;
        this.setState({question, textInputProps: null});
    }

    getNextId = () => {
        const {question} = this.state;
        return Math.max( ...question.answers.map( a => a.id as number) , 0 ) + 1;
    }

    updateCorrectAnswer = (correctAnswer : number ) => {
        const {question} = this.state;
        if( ! question.answers.find( a => a.id === correctAnswer )){
            return notify("Ответ с таким ID не найден");
        }
        // update correct answer
        question.correctAnswer = correctAnswer;
        this.setState({question});
    }

    removeAnswer = ( id: number|null ) => {
        const {question} = this.state;
        const answerIndex = question.answers.findIndex( a => a.id === id );
        // check if exists
        if( !(answerIndex>=0) ) {
            return notify("Ответ с таким ID не найден");
        }

        // remove Answer
        question.answers.splice(answerIndex, 1);
        this.setState({question});
    }

    render(){
        const {question : propsQuestion, onUpdate, ...modalProps} = this.props;
        const {question, textInputProps} = this.state;
        
        return(
            <>
                <ModalDialog {...modalProps} header={question.question}>
                    <Typography variant="h3">Ответы:</Typography>
                    <div className="flex row">
                        <Button variant="contained" color="primary" onClick={() => {
                            this.setState({
                                textInputProps: {
                                    open: true,
                                    onClose: () =>  this.setState({textInputProps: null}),
                                    onConfirm: (text: string) => this.updateAnswer({ id: null, text }),
                                    value: ""
                                }
                            })
                        }}><Add/> </Button>
                    </div>
                    <List>
                        {question.answers.map( a => <MenuItem key={`answer-${a.id}`} className="flex row content-space-between items-center">
                            
                            <span>
                                { question.correctAnswer !== null && question.correctAnswer === a.id ? <Check className="color-green"/> : <Close className="color-red" /> }                                 
                                {a.text}
                            </span>
                            <span>                                                                
                                <IconButton onClick={() => {
                                     
                                        this.setState({
                                            textInputProps: {
                                                open: true,
                                                onClose: () =>  this.setState({textInputProps: null}),
                                                onConfirm: (text: string) => this.updateAnswer({ ...a, text }),
                                                value: a.text
                                            }
                                        })
                                } }><Edit/></IconButton> 
                                <IconButton onClick={()=> this.removeAnswer(a.id)}><Delete/></IconButton> 
                                { (question.correctAnswer === null || (question.correctAnswer !== null && question.correctAnswer !== a.id)) && 
                                <IconButton onClick={() => this.updateCorrectAnswer(a.id as number)}><Check/></IconButton> }
                            </span>
                            
                        </MenuItem>)}
                    </List>
                    <div className="flex row content-space-between">
                        <Button variant="contained" color="primary" onClick={() => onUpdate(question) }>Сохранить</Button>
                        <Button variant="contained" color="secondary" onClick={modalProps.onClose}>Отмена</Button>
                    </div>
                </ModalDialog>
                <TextInputDialog {...textInputProps} />            
            </>
        )
    }
}

export default GameQuestionAnswers