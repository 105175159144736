import BaseClientService from  "./BaseClientService"
import store from "../redux/store";
import { saveUsers } from "../redux/dbActions";
import { arraysIntersect } from "../utils/functions";

class UserService extends BaseClientService {

    userList = null

    static async getAll(){
        // if( !this.teamList ){
        //     this.teamList = [...teams];
        // }
        this.userList = store.getState().db.users;

        const t = this.userList;
        console.debug("users:", t)
        
        return t;
    }

    static async getOne( id ){
        return (await this.getAll()).find( u => u.id === id)
    }

    /**
     * Filter by ids
     * @param {array} ids 
     * @returns 
     */
    static async getByIds( ids ){
        const list = await this.getAll();

        return list.filter( t => ids.includes(t.id))
    }

    static async add( data ){
        const {title} = data;

        const id = await this.getNextId();
        // add element
        this.userList = [ ... await this.getAll(), 
            {
                id, title
            }
        ]

        // update in state
        saveUsers(this.userList);

        return id;
    }

    static async update( data ){
        const {id, title} = data;

        if(!id) return this.Error("empty id");
        const list = await this.getAll();
        const itemIndex = list.findIndex( t => t.id === id );
        if( !itemIndex ) return this.Error(`Item with id [${id}] was not found`)

        this.userList[itemIndex] = {...list[itemIndex], title}

        // update in state
        saveUsers(this.userList);
        return true

    }

    static async delete(id){
        const itemIndex = (await this.getAll()).findIndex( t => t.id === id );

        if( !(itemIndex >=0 ) ) return this.Error(`Item with id [${id}] was not found`)

        // remove
        this.userList.splice(itemIndex, 1);

        // update in state
        saveUsers(this.userList);
        return true;
    }

    static async validateUser( email, pass, credentials = ["admin", "captain"] ){
        const all = await this.getAll();
        return all.find( u => u.email === email && u.password === pass && arraysIntersect(credentials, u.credentials) );
    }

    static async getNextId(){
        const all = await this.getAll();

        return Math.max( ...all.map( t => t.id) ) + 1;
    }
}

BaseClientService.extend(UserService);

export default UserService;