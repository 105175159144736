import { Badge, Container, Fab, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import GameQuestions from './game-questions';
import GameQuestion from './game-question';
import { Alert } from '@material-ui/lab';
import './captain-game.scss'
import {notify} from '../../redux/reduxFunctions';
import GameProgressService from '../../services/game-progress-service';
import { Close, Description } from '@material-ui/icons';
import GameService from '../../services/game-service';
import ModalDialog from '../template/modal-dialog';
import GameCurrentContent from '../games/game-current-content';


class CaptainGame extends React.Component{
    state = {
        content: [],
        showContent: false
    }

    componentDidMount(){
        this.fetchData();
    }

    componendDidUpdate( prevProps ){
        if( prevProps.game !== this.props.game ){
            this.fetchData();
        }
    }

    fetchData = async() => {
        const {game} = this.props;
        console.debug("CaptainGame.fetchData called")
        if( game && game.id ){
            console.debug("fetchData calling GameService")
            const content = await GameService.getCurrentGameContent(game.id);
            this.setState({content});
        }
    }

    answerSelected = async (answer) => {
        console.debug("answer selected:", answer)

        if( !await GameProgressService.add( answer ) ){
            return notify( GameProgressService.error || "Unknown error")
        }

        // update parent
        this.props.onUpdate();
    }

    render(){
        let {game, question_id, onUpdate} = this.props;
        const {content, showContent} = this.state;

        console.debug("content in render:", content);

        console.debug( "game is:", game)

        let question = question_id && game && Array.isArray(game.questions) ? game.questions.find(q => q.id === question_id) : null;
        console.debug("question:", question, "question_id", question_id)
        const {current_section, sections} = game
        const section = sections.find( s => s.order === current_section);

        const gameIsStarted = Boolean(game.started_at);
        const sectionIsStarted = Boolean(section?.started_at);

        return(<Container>
            <Typography variant="h1">{game.title}</Typography>
            { gameIsStarted && current_section !== null && sectionIsStarted && !game.finished_at  && 
                <Alert severity='info' className="m-top-10 m-bottom-10">   Фаза {current_section + 1}, {section ? `${section.score} очков` : `ошибка`}</Alert>
            }
            { gameIsStarted && !question && Array.isArray(game.questions) && <GameQuestions {...game}/>}
            { gameIsStarted && question && <GameQuestion {...question} game={game} section={section} onSelect={this.answerSelected} />}
            {!gameIsStarted && "Игра ещё не началась"}

            
                <Fab className="content-button" color="primary" onClick={() => this.setState({showContent: true})}>
                    <Badge color="secondary" badgeContent={(Array.isArray(content) ? content.length : 0) }>
                        <Description size="large" />
                    </Badge>
                </Fab>
                <ModalDialog open={showContent} header="Материалы дела" onClose={() => this.setState({showContent: false})}>
                    <IconButton className="content-close-button" onClick={() => this.setState({showContent: false})}><Close className="color-red"/></IconButton>
                    <GameCurrentContent content={content} />
                </ModalDialog>
        </Container>)
    }
}

export default CaptainGame