import { Container } from '@material-ui/core'
import React from 'react'
import GameService from '../../services/game-service';
import Dashboard from '../Dashboard';
import CaptainGame from './captain-game';
import Page404 from '../template/Page404';
import GameQuestion from './game-question';


class CaptainHome extends React.Component{


    state = {
        userGames: null
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData = async () => {
        const now = new Date().toISOString();
        console.debug( "now", now);
        const {user} = this.props;
        let userGames = await GameService.getUserGames( user.id );
        userGames = userGames.map( ug => ({
            ...ug, 
            to: `/game/${ug.id}`,
            wrapperClass: ug.date < now ? "bg-gray-important" : "" ,
            _children: [
                {
                    itemType: "text",
                    text: ug.date
                }
            ]
        })).sort( (a,b) => a.date > a.date ? 1 : -1 )



        this.setState({userGames})
    }

    render(){

        let {game_id, question_id} = this.props?.match?.params;
        
        // parse as int, if any
        game_id =  game_id ? parseInt(game_id) : game_id;
        question_id =  question_id ? parseInt(question_id) : question_id;

        
        const {userGames} = this.state;

        const game = game_id && Array.isArray(userGames) ? userGames.find( g => g.id === game_id) : null;
        
        console.debug("user games:", userGames)
        

        return(
            <Container className='flex-grow'>                
                { (!game && !game_id && Array.isArray(userGames)) && <Dashboard items={userGames} />}  
                { (game_id && !game) && <Page404/> }                              
                { game && <CaptainGame {...{game, question_id}} onUpdate={this.fetchData} />}                
            </Container>
            )
    }
}

export default CaptainHome