import React from 'react'
import ErrorPage from '../ErrorPage';

class Page404 extends React.Component{

    render(){
        return( 
            <ErrorPage headerText="Error 404" text="Page not found" {...this.props} />
        )
    }
}

export default Page404