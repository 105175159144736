import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'
import ModalDialog from '../template/modal-dialog'
import UserService from '../../services/user-service'


const defaultProps = {
    header: "Select user to log in as"
}

class SelectUser extends React.Component{

    state = {
        users: null
    }

    async componentDidMount(){
        const users = await UserService.getAll();
        this.setState({users})
    }

    selectUser = (user) => {
        const {onClose, onSelect = console.debug} = this.props;
        onClose();
        onSelect(user);
    }

    render(){
        const { alreadySelected = [], ...dialogProps} = this.props
        const {users} = this.state;
        return(
            <ModalDialog {...{...defaultProps,  ...dialogProps}}>
                { users === null && <CircularProgress />}
                { Array.isArray(users) && 
                <table className="striped click-list">
                    <thead>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Credentials</th>                        
                    </thead>
                    <tbody>
                        {users.
                        // filter out already selected
                        filter( u => !alreadySelected.includes(u.id)) .
                        // display
                        map( u => <tr key={`user-${u.id}`} onClick={() => this.selectUser(u)}>
                            <td>{u.name}</td>
                            <td>{u.email}</td>
                            <td>{Array.isArray(u.credentials) && u.credentials.join(", ")}</td>
                        </tr>)}
                    </tbody>
                </table>
                }
                <Button variant="contained" color="secondary" onClick={this.props.onClose}>Cancel</Button>
            </ModalDialog>
        )
    }
}

export default SelectUser