import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'
import ModalDialog from '../template/modal-dialog'
import TeamService from '../../services/team-service'


const defaultProps = {
    header: "Select team"
}

class SelectTeam extends React.Component{

    state = {
        teams: null
    }

    async componentDidMount(){
        const teams = await TeamService.getAll();
        this.setState({teams})
    }

    selectTeam = (team) => {
        const {onClose, onSelect = console.debug} = this.props;
        onClose();
        onSelect(team);
    }

    render(){
        const { alreadySelected = [], ...dialogProps} = this.props
        const {teams} = this.state;
        return(
            <ModalDialog {...{...defaultProps,  ...dialogProps}}>
                { teams === null && <CircularProgress />}
                { Array.isArray(teams) && 
                <table className="striped click-list">
                    <thead>
                        <th>Название</th>
                        <th>Очки</th>                        
                    </thead>
                    <tbody>
                        {
                        teams.
                        // filter out already selected
                        filter( t => !alreadySelected.includes(t.id)).
                        // display
                        map( t => <tr key={`user-${t.id}`} onClick={() => this.selectTeam(t)}>
                            <td>{t.title}</td>                            
                            <td>{t.score}</td>                            
                        </tr>)}
                    </tbody>
                </table>
                }
                <Button variant="contained" color="secondary" onClick={this.props.onClose}>Закрыть</Button>
            </ModalDialog>
        )
    }
}

export default SelectTeam