import gamesData from '../data/games.json';
import usersData from '../data/users.json';
import teamsData from '../data/teams.json';
import progressData from '../data/game-progress.json'
import contentData from '../data/content.json'

const actionTypes = {
    SAVE_TEAMS: "SAVE_TEAMS",
    SAVE_GAMES: "SAVE_GAMES",
    SAVE_USERS: "SAVE_USERS",
    SAVE_PROGRESS: "SAVE_PROGRESS",
    SAVE_CONTENT: "SAVE_CONTENT",
    RESET_DATABASE: "RESET_DB"
}

const localStorageKey = process.env.REACT_DB_KEY || 'GameManagerDB';

let localStorageState;

try {
  localStorageState = localStorage.getItem(localStorageKey)
    ? JSON.parse(localStorage.getItem(localStorageKey))
    : {};
} catch (e) {
  localStorageState = {};
}

const initialState = {
  ...{
    games: [...gamesData],
    teams: [...teamsData],
    users: [...usersData],
    progress: [...progressData],
    content: [...contentData]
  },
  // overwrite values from local state, if any
  ...localStorageState,
};

function root(state = {...initialState}, action) {

  let newState = { ...state };
  const {type, payload} = action;
  console.debug("db reducers:", type, payload)

  switch (type) {
    case actionTypes.SAVE_GAMES:
        if( !Array.isArray(payload)) {
            console.debug("Wrong type of Games data. Expected array, got:",payload)
            break;
        };
      newState = {...newState, games: payload}
      break;

    case actionTypes.SAVE_TEAMS:
        if( !Array.isArray(payload)) {
            console.debug("Wrong type of Teams data. Expected array, got:",payload)
            break;
        };
      newState = {...newState, teams: payload}
      break;

    case actionTypes.SAVE_USERS:
        if( !Array.isArray(payload)) {
            console.debug("Wrong type of Users data. Expected array, got:",payload)
            break;
        };
      newState = {...newState, users: payload}
      break;

    case actionTypes.SAVE_PROGRESS:
        if( !Array.isArray(payload)) {
            console.debug("Wrong type of Progress data. Expected array, got:",payload)
            break;
        };
      newState = {...newState, progress: payload}
      break;

    case actionTypes.SAVE_CONTENT:
        if( !Array.isArray(payload)) {
            console.debug("Wrong type of Content data. Expected array, got:",payload)
            break;
        };
      newState = {...newState, content: payload}
      break;

    case actionTypes.RESET_DATABASE:
      // reset to presets
      newState = {
        ...{
          games: [...gamesData],
          teams: [...teamsData],
          users: [...usersData],
          progress: [...progressData],
          content: [...contentData]
        }
      }
      break;

    default:
    //newState = state;
  }

  localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}

root.actionTypes = actionTypes;

export default root;