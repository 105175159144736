import React from 'react'
import ModalDialog from '../template/modal-dialog';
import { Button,  CircularProgress,  TextField } from '@material-ui/core';


class TeamForm extends React.Component{

    state = {
        team: null
    }

    componentDidMount(){
        const {team} = this.props;
        this.setState({team})
    }
    
    componentDidUpdate( prevProps ){
        const { team} = this.props;
        if( prevProps?.team?.id !== team?.id ){
            this.setState({team})
        }
    }

    update = ({target}) => {
        const {name, value} = target;
        const {team} = this.state;

        this.setState({team: {...team, [name]: value}})
    }

    render(){
        const {onSave, onClose, team: propsTeam, ...other} = this.props;

        const {team} = this.state;
        return(<ModalDialog {...other} onClose={onClose} onSubmit={(ev)=> {ev.preventDefault(); onSave(team)}} >
            {!team && <CircularProgress/>}
            {team && 
                <div className="flex column">
                    
                        
                        <TextField
                            label="Team title"
                            name="title"
                            value={team?.title}                    
                            onChange={this.update}
                        />
                    <div className="flex row content-space-between">
                        <Button onClick={() => onSave(team)}>Ok</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
                </div>
            }
        </ModalDialog>)
    }
}

export default TeamForm