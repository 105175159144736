import { CircularProgress, Container, Fab } from '@material-ui/core';
import React from 'react'
import TeamService from '../../services/team-service';
import Dashboard from "../Dashboard"
import ConfirmDialog from '../template/ConfirmDialog';
import { Add } from '@material-ui/icons';
import TeamForm from './team-form';
import { notify } from '../../redux/reduxFunctions';
import Page404 from '../template/Page404';
import TeamProperties from './team-properties';


class Teams extends React.Component{

    state = {
        teams: null,
        editTeam: null,
        confirmTitle: null,
        confirmPrompt: null,
        onConfirm: null,
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData = async () => {
        let teams = await TeamService.getAll();

        teams = teams.map( t => ({...t, to: `/teams/${t.id}`}))

        this.setState({teams});
    }

    update = async (data) => {

        let res;
        if( !data.id ){
            res = await TeamService.add(data);
        } else {
            res = await TeamService.update(data);
        }

        if( !res ) return notify( TeamService.error || "Unknown error");

        // close the form
        this.setState({editTeam: null})

        // update data
        this.fetchData();
    }



    render(){

        const {teams, editTeam, confirmPrompt, confirmTitle, onConfirm} = this.state;
        let {team_id} = this.props.match.params;

        team_id = team_id ? parseInt(team_id) : team_id;

        const team = team_id && teams ? teams.find(t => t.id === team_id) : null;
        console.debug( "Teams team id:", team_id, ", team:", team)
        if( !teams ) return <CircularProgress />

        if( team_id && !team ) return <Page404/>
        if( team_id && team ) return <Container><TeamProperties {...team} onUpdate={this.fetchData} /></Container>
        if( teams && !team_id && !team) return(
            <Container>
                <Dashboard
                    items={teams}
                    showSearch={true}
                />
                <ConfirmDialog open={Boolean(confirmPrompt)} header={confirmTitle} prompt={confirmPrompt} onConfirm={onConfirm} onClose={this.closeConfirmDialog} />
                <Fab color="primary" className="fab" onClick={() => this.setState({editTeam: {...TeamService.defaultTeam}})}><Add/> </Fab>
                <TeamForm formClassName="shrink" open={Boolean(editTeam)} team={editTeam} onClose={()=> this.setState({editTeam: null})} onSave={this.update} />
            </Container>
        )
    }
}

export default Teams