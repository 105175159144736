import React from 'react'
import {AppBar, Container} from '@material-ui/core';
import { Eco, Person } from '@material-ui/icons';
import "./navbar.scss"; 

const defaultAppBarProps = {
    color: "transparent",
    className: "app-bar-component"
}

class Navbar extends React.Component{

    render(){
        const {
            Logo = <Eco color="primary" />, // default logo component
            TopRight = <Person color="primary" />,
            AppBarProps = {},
            ContainerComponent = Container,
            MiddleComponent = <div></div>,
            user
        } = this.props;
        return<>
            <AppBar color="transparent" {...{...defaultAppBarProps, ...AppBarProps} } >
                <ContainerComponent className="app-bar-container">
                    {Logo}
                    {MiddleComponent}
                    <TopRight {...{user}} />
                </ContainerComponent>
            </AppBar>
        </>
    }
}

export default Navbar