import { Typography } from '@material-ui/core'
import React from 'react'


class Profile extends React.Component{

    render(){
        return(
            <>
                <Typography variant="h1">Profile</Typography>
            </>
        )
    }
}

export default Profile