import React from 'react'
import { Person } from '@material-ui/icons'
import {Button, Menu, MenuItem} from '@material-ui/core'

import { Link } from 'react-router-dom'
import { logOut } from '../../redux/authActions'

class SecuredUserBlock extends React.Component{

    state = {        
        menuAnchor: null
    }

    render(){
        const {menuAnchor} = this.state
        const { user } = this.props;

        return(<div className="user-block">
            <Button onClick={ ({target}) => this.setState({menuAnchor: target})}>
                <Person color="secondary" /> {user?.name || ""}
            </Button>

            <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => this.setState({menuAnchor: null})}>
               <MenuItem><Link to="/profile">Profile</Link></MenuItem>  
               <MenuItem onClick={logOut}>Log out</MenuItem>  
            </Menu>
        </div>)
    }
}

export default SecuredUserBlock