import { AppBar, Container, IconButton, List, MenuItem } from '@material-ui/core'
import { Settings, Replay, PersonPin, Lock } from '@material-ui/icons'
import React from 'react'
import { resetDb } from '../../../redux/dbActions';
import { logOut, replaceUser } from '../../../redux/authActions';
import SelectUser from '../../users/select-user';


const appStyle = {
    top: "auto", 
    bottom: 0,
    transition: "all 0.5s"
}

const openBarHeight = "50%";
const closedBarHeight = "0.4em";

class OptionsDrawer extends React.Component{

    state = {
        height: closedBarHeight,
        open: false,
        showSelectUser: false
    }

    toggleBarOpen = () => {
        let {height} = this.state;
        height = (height==="auto") ? closedBarHeight : "auto";
        this.setState({height})
    }

    onLoginAsOtherUser = (user) => {
        // remove password from user data
        const {password, ...other} = user

        this.toggleBarOpen();
        replaceUser(other)
    }

    render(){
        const {height, showSelectUser } = this.state;

        return(

            <AppBar position="fixed" style={{ ...appStyle }} color="inherit" className='options-drawer' >
                <div className="flex row content-space-between">
                    <IconButton size='small' onClick={this.toggleBarOpen}><Settings/></IconButton>
                </div>
                <Container style={{height}}>
                    <List>
                        <MenuItem onClick={() => this.setState({showSelectUser: true})}>
                            <Lock/> Login as other user
                        </MenuItem>
                        <MenuItem onClick={logOut}>
                            <PersonPin/> Reset authentication
                        </MenuItem>
                        <MenuItem onClick={resetDb}>
                            <Replay/> Reset DB
                        </MenuItem>
                    </List>
                </Container>
                <SelectUser open={showSelectUser} onClose={() => this.setState({showSelectUser: false})} onSelect={this.onLoginAsOtherUser} />
            </AppBar>
        )
    }
}

export default OptionsDrawer