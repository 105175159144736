
import React from 'react'
import MenuBlock from '../menu-block';
import { Typography } from '@material-ui/core';

const TextItem = ({text, _children, typographyProps={}}) =><div className="text-item">
    <Typography {...typographyProps}>{text}</Typography>
    {_children && <MenuBlock items={_children} />}
</div>


export default TextItem