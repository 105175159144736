import React from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Email, Lock, AccountCircle as UserIcon } from '@material-ui/icons';
import store from '../../redux/store';
import A from '../../redux/actionTypes';
import Service from '../../services/AuthService';

import './login.scss';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    connecting: false,
  };

  validateForm = (ev) => {
    return ev.currentTarget.reportValidity();
  };

  login = async (ev) => {
    // catch submit
    ev.preventDefault();

    if (this.validateForm(ev)) {
      const { email, password } = this.state;

      if (email && password) {
        console.debug('Logging in');
        this.setState({ connecting: true }, () => {
          this.setState({ connecting: false });
          this.doLogin(email, password);
        });
      }
    }
  };

  doLogin = async (email, password) => {
    const result = await Service.login(email, password);

    if (result) {
      const { token, user } = result;
      store.dispatch( { type: A.LOGIN, payload: { user, token } });
      return true;
    } 

    store.dispatch({type: A.SHOW_MESSAGE, payload: {message: Service.error, type: "error"}})
    console.error('Error logging in: ', result);    
    return false;
  };

  updateValue = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  render() {
    const { email, password, connecting } = this.state;

    return connecting ? (
      <Container>
        <CircularProgress /> Logging in...{' '}
      </Container>
    ) : (
      <Container id='login'>
        <form noValidate className='login-form' onSubmit={this.login}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <UserIcon fontSize='large' color='primary' />
              <br />
              <Typography variant='h6' color='primary'>
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor='input-with-icon-adornment'>
                  Email
                </InputLabel>
                <Input
                  id='input-with-icon-adornment'
                  autoComplete='username'
                  type='email'
                  name='email'
                  onChange={this.updateValue}
                  value={email}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Email color='primary' />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor='input-with-icon-adornment2'>
                  Password
                </InputLabel>
                <Input
                  id='input-with-icon-adornment2'
                  type='password'
                  name='password'
                  autoComplete='current-password'
                  onChange={this.updateValue}
                  value={password}
                  startAdornment={
                    <InputAdornment position='start'>
                      <Lock color='primary' />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' color='primary' type='submit'>
                Log in
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

export default Login;
