import React from 'react'
import Dashboard from "../Dashboard"


class GameQuestions extends React.Component{

    render(){
        let {questions, id, answers} = this.props;

        questions = Array.isArray(questions) ? questions.map( q => {
            return {
                title: q.question,
                to: `/game/${id}/question/${q.id}`,
                wrapperClass: `question-dashboard-item${ answers.find( a => a.question_id === q.id ) ? ' answered' : '' }`,
            }
        } ) : questions;

        console.debug("question list:", questions)

        return(
            <div className="question-list">
            {Array.isArray(questions) && <Dashboard items={questions} /> }
        </div>)
    }
}

export default GameQuestions