import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import Footer from '../template/footer';
import Navbar from '../template/Navbar';
import Routes from '../Routes';
import routes from '../../data/non-secured_routes';
import Login from '../Auth/login';

class NonSecuredMain extends React.Component {
  render() {
    const { location, navbarProps } = this.props;
    console.debug("navbarProps:", navbarProps)
    return (
      <Router>
        {/* <Navbar location={location} {...navbarProps} /> */}
        <Login />
        {/* <div className='main'>
          <Routes routes={routes} id='main' />
        </div> */}
        {/* <ReduxModal /> */}
        {/* <Notification /> */}
        {/* <Footer /> */}
      </Router>
    );
  }
}

export default NonSecuredMain;
