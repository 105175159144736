const ActionTypes = {
  
  RESET_CONTACT: 'RESET_MESSAGE',

  SHOW_MESSAGE: 'SHOW_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
  

  SHOW_MODAL: 'SHOW_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  LOGIN: 'LOGIN',
  REPLACE: 'REPLACE',
  LOGOUT: 'LOGOUT',
};

export default ActionTypes;
