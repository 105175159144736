import { Button, IconButton } from '@material-ui/core'
import { Add, Delete, Description, Edit } from '@material-ui/icons'
import React from 'react'
import TextInputDialog from '../template/text-input-dialog';
import GameQuestionAnswers, { GQAProps, IModalDialog } from './game-question-answers';
import GameService from '../../services/game-service';
import { notify } from '../../redux/reduxFunctions';
import { Answer, Question } from './types';
import ConfirmDialog from '../template/ConfirmDialog';


interface GQState {
    inputDialogProps: object|null,
    answersFormProps: object|null,
    confirmDialogProps: IConfirmDialogProps|null
}

interface IConfirmDialogProps extends IModalDialog{
    onConfirm: () => void,
    prompt ?: string
}

interface GQProps {
    game_id: number|null,
    questions: Question[]
    onUpdate: () => undefined
}

class GameQuestions extends React.Component<GQProps, GQState>{

    state = {
        inputDialogProps: null,
        answersFormProps: null,
        confirmDialogProps: null
    }

    showInputDialog = (inputDialogProps : object) => this.setState({inputDialogProps});

    closeInputDialog = () => this.setState({inputDialogProps: null})
    closeAnswersForm = () => this.setState({answersFormProps: null})

    addQuestion = async (question : string ) => {
        console.debug("add", question)
        const {game_id} = this.props;
        if( !await GameService.addQuestion(game_id, {question})){
            return notify( GameService.error || "Unknown error")
        }
        this.props.onUpdate();

    }
    onUpdateQuestion = async (data : Question) => {
        console.debug(data)
        const {game_id} = this.props;
        if( !await GameService.updateQuestion( game_id, data )){
            return notify( GameService.error || "Unknown error")
        }        
        this.props.onUpdate();
    }

    deleteQuestion = async (id: number) => {
        const {game_id} = this.props;
        if( ! await GameService.deleteQuestion(game_id, id)){
            return notify( GameService.error || "Unknown error")
        }
        this.setState({confirmDialogProps: null})
        this.props.onUpdate();
    }
    
    render(){
        const {questions} = this.props;
        const {inputDialogProps, answersFormProps, confirmDialogProps} = this.state;

        return(
            <div className="game-questions m-bottom-20">
                <div className="flex row content-space-between">
                    <Button variant="contained" color="primary" onClick={() => {
                        this.setState({
                            inputDialogProps : {
                            onConfirm: (question : string ) => {this.addQuestion( question ); this.setState({inputDialogProps: null})},
                            onClose: this.closeInputDialog,
                            header: "Введите текст вопроса",
                            value: ""
                        }})
                    }}><Add/></Button>
                </div>
                <table className="striped" style={{width: "100%"}}>
                    <thead>
                        <tr>
                        <th>Вопрос</th>
                        <th>Ответы</th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions?.map( q => (
                        <tr key={`question-${q.id}`}>
                            <td>
                                {q.question}
                                <IconButton size="small" onClick={() => {
                                    this.setState({
                                        inputDialogProps : {
                                        onConfirm: (question : string) => { this.onUpdateQuestion( {id: q.id, question} as Question); this.setState({inputDialogProps: null})},
                                        onClose: this.closeInputDialog,
                                        header: "Введите текст вопроса",
                                        value: q.question
                                    }})
                                }}><Edit/></IconButton>
                            </td>
                            <td>
                                {q.answers?.length || 0}
                                <IconButton size="small" onClick={() => {
                                    this.setState({
                                        answersFormProps : {
                                        onUpdate: (qq: Question) => {this.onUpdateQuestion( qq ); this.setState({answersFormProps: null})},
                                        onClose: this.closeAnswersForm,
                                        header: `Ответы`,
                                        question: q,
                                        open: true
                                    }})
                                }}><Description/></IconButton>
                            </td>
                            <td>
                                <IconButton onClick={() => this.setState({
                                    confirmDialogProps: {
                                        open: true,
                                        prompt: `Подтвердите удаление вопроса "${q.question}" вместе со всеми ответами на него`,
                                        onConfirm: () => { this.deleteQuestion( q.id as number) },
                                        onClose: () => this.setState({confirmDialogProps: null})
                                    }
                                })}><Delete/></IconButton>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                {inputDialogProps !== null && <TextInputDialog open={true} {...(inputDialogProps as object) || {}} /> }
                { answersFormProps && <GameQuestionAnswers {...answersFormProps as GQAProps }/> }
                { confirmDialogProps && <ConfirmDialog {...confirmDialogProps as IConfirmDialogProps} />}
            </div>
        )
    }
}

export default GameQuestions