import dbReducers from './dbReducers';
import store from './store';

const actionTypes = dbReducers.actionTypes;


export const saveGames = (payload) => {
  console.log("dispatching SAVE_GAMES")
  store.dispatch({type: actionTypes.SAVE_GAMES, payload});  
};
export const saveTeams = (payload) => {
  console.log("dispatching SAVE_TEAMS")
  store.dispatch({type: actionTypes.SAVE_TEAMS, payload});  
};
export const saveUsers = (payload) => {
  console.log("dispatching SAVE_USERS")
  store.dispatch({type: actionTypes.SAVE_USERS, payload});  
};

export const saveProgress = (payload) => {
  console.log("dispatching SAVE_PROGRESS")
  store.dispatch({type: actionTypes.SAVE_PROGRESS, payload});  
};

export const saveContent = (payload) => {
  console.log("dispatching SAVE_CONTENT")
  store.dispatch({type: actionTypes.SAVE_CONTENT, payload});  
};


export const resetDb = () => {
  console.log("dispatching RESET_DATABASE")
  store.dispatch({type: actionTypes.RESET_DATABASE});  
};
