import React from 'react'
import ModalDialog from '../template/modal-dialog';
import { Button,  TextField } from '@material-ui/core';


class GameForm extends React.Component{

    state = {
        game: null
    }

    componentDidMount(){
        const {game} = this.props;
        this.setState({game})
    }
    
    componentDidUpdate( prevProps ){
        const {game} = this.props;
        if( prevProps?.game?.id !== game?.id ){
            this.setState({game})
        }
    }

    update = ({target}) => {
        const {name, value} = target;
        const {game} = this.state;

        this.setState({game: {...game, [name]: value}})
    }

    render(){
        const {onSave, onClose, game: propsGame, ...other} = this.props;

        const {game} = this.state;
        return(<ModalDialog {...other} onClose={onClose} onSubmit={(ev)=> {ev.preventDefault(); onSave(game)}} >
            <div className="flex column">
                
                    
                    <TextField
                        label="Game title"
                        name="title"
                        value={game?.title}                    
                        onChange={this.update}
                    />
                <div className="flex row content-space-between">
                    <Button onClick={() => onSave(game)}>Ok</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </div>
        </ModalDialog>)
    }
}

export default GameForm