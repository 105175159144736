
import React from 'react'
import MenuBlock from '../menu-block';

const ContentItem = ({content, _children }) =><div className="content-item">
    {content}
    {Array.isArray(_children) && <MenuBlock items={_children} />}
</div>


export default ContentItem