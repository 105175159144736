import { FormControl, InputAdornment, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React from 'react'


class SearchBlock extends React.Component{

    render(){
        return(<div className="search-block align-stretch m-bottom-20">
            
                <TextField  
                fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                    }}
                    variant="outlined"
                
                />
            
        </div>)
    }
}

export default SearchBlock