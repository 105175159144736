import Base from './BaseClientService';
import UserService from './user-service';


class AuthService extends Base {
  static async login( login, password){
    // return { user: { id: 1, name: "Default user", email: "default@test.cc", credentials: ["admin"]}, token: "asdfsd"}

    const user = await UserService.validateUser(login, password);
    if( !user ) return this.Error("Error logging in!")
    return {user, token: "sdsdfsdf"}
    // return this.Error("Ошибка при входе");
  }
}
Base.extend(AuthService);

export default AuthService;
