import React from 'react'
import {Container, Typography} from '@material-ui/core';

class ErrorPage extends React.Component{

    render(){
        const {
            headerVariant = "h1",
            textVariant = "body1",
            headerText = "Error",
            text = "Unknown error happened",
            cssClassName = "error-page"
        } = this.props 
        
        return(
            <Container className={cssClassName}>
                <Typography variant={headerVariant}>{headerText}</Typography>
                <Typography variant={textVariant}>{text}</Typography>                
            </Container>
        )
    }
}

export default ErrorPage