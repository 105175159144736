import React from 'react'


class PlayerHome extends React.Component{

    render(){
        return("Player home")
    }
}

export default PlayerHome