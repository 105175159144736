
import React from 'react'
import menu from '../../../data/menu.json'
import Dashboard from '../../Dashboard'
import { Container } from '@material-ui/core';

class Home extends React.Component{

    render(){
        const {user} = this.props;
        console.debug("items in Home:", menu)
        console.debug("user in Home:", user)
        return(
            <Container>
                <Dashboard 
                    items={menu}
                    user={user}
                    showSearch={false}
                />
            </Container>
        )
    }
}

export default Home