import { Container } from '@material-ui/core'
import React from 'react'


class Users extends React.Component{

    render(){
        return(<Container>Users</Container>)
    }
}

export default Users