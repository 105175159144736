import React from 'react'
import { Person } from '@material-ui/icons'
import {Button, Paper, Dialog} from '@material-ui/core'
import LoginRegister from './LoginRegister'

class NonSecuredUserBlock extends React.Component{

    state = {
        showPopup: false
    }

    togglePopup = () => {
        console.debug(" toggling popup:", this.state.showPopup )
        this.setState( {showPopup: !this.state.showPopup})
    }

    closePopup = () => this.setState({showPopup: false})

    render(){
        const {showPopup} = this.state
        const { loginRegisterProps = {} } = this.props;

        return(<div className="user-block">
            <Button onClick={this.togglePopup}>
                <Person />
            </Button>

            <Dialog open={showPopup} onClose={this.closePopup}>
                <Paper>
                    <LoginRegister {...loginRegisterProps} />
                </Paper>
            </Dialog>
        </div>)
    }
}

export default NonSecuredUserBlock