import React from 'react';
import { arraysIntersect } from '../../utils/functions';
import "./style.scss";
import store from "../../redux/store";
import SearchBlock from './search-block';
import LinkItem from './items/link-item';
import TextItem from './items/text-item';
import ContentItem from './items/content-item';


const itemTypes = ["link", "text", "content"]

const MenuBlock = ({ items, showSearch = false }) => {

    console.debug("items in menu block", items)
    // get user credentials from redux
    const userCredentials = store.getState().auth?.user?.credentials || [];
    console.debug("user credentials:", userCredentials)
    return (
        <div className={`menu-block-wrapper flex${showSearch ? " has-search" : ""}`}>
            {showSearch && <SearchBlock />}
            <ul className="menu-block">
                {Array.isArray(items) &&
                items.map((item, index) => {
                    const { title, to, _children, credentials = [], itemType ="link", wrapperClass = '' } = item;
                    // only show element if user has access to it (no credentials are set for the element or user has at least one of the credentials)
                    if (
                    !Array.isArray(credentials) ||
                    credentials.length === 0 ||
                    arraysIntersect(userCredentials, credentials)
                    ) {
                    return (
                        <li key={`item-${index}`} className={`${wrapperClass}`}>
                            { 
                            // default - link
                            (itemType === "link" || !itemTypes.includes(itemType)) && <LinkItem {...item} />}
                            {
                                itemType === "text" && <TextItem {...item} />
                            }
                            {
                                itemType === "content" && <ContentItem {...item} />
                            }
                        {/* <Link to={to}>{title}</Link>
                        {_children && <MenuBlock items={_children} />} */}
                        </li>
                    );
                    }
                })}
            </ul>
        </div>
    );
  };


  export default MenuBlock;