import React from "react";
import "./style.scss";
import MenuBlock from "./menu-block";





class Dasboard extends React.Component {
  render() {
    const {items = [], ...other } = this.props;
    return (
      <div className="dashboard">
        <MenuBlock items={items} {...other} />
      </div>
    );
  }
}

export default Dasboard;
