import React from 'react'
import NonSecuredUserBlock from "../components/Auth/NonSecuredUserBlock"
import SecuredUserBlock from '../components/Auth/SecuredUserBlock'
import { Link } from 'react-router-dom'
import { Games } from '@material-ui/icons'


const nonSecuredAppProps = {
    navbarProps: {
        Logo: <Link to="/" className="logo"><Games/></Link>,
        TopRight: <NonSecuredUserBlock 
        {...{
            loginRegisterProps: {
                title: "New App 1"
            }
        }}
        />
    }
}

const securedAppProps = {
    navbarProps: {
        Logo: <Link to="/" className="logo"><Games/></Link>,
        TopRight: SecuredUserBlock
    }
}

const notificationProps = {
    clearText: "Очистить",
    showText: "Показать",
    hideText: "Скрыть", 
    NmessagesText: "%n%",
    maxMessagesToShow: 3
}


export {nonSecuredAppProps, securedAppProps, notificationProps }