import { Button, Container, List, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import ConfirmDialog from '../template/ConfirmDialog';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom/cjs/react-router-dom';


class GameQuestion extends React.Component{

    state = {
        confirmTitle: null,
        confirmPrompt: null,
        onConfirm: null,
    }

    closeConfirmFialog = () => {
        this.setState({
            confirmTitle: null,
            confirmPrompt: null,
            onConfirm: null,
        })
    }

    answerSelected = (answer) => {
        const {id, game, section, onSelect} = this.props;

        onSelect({
            game_id: game.id,
            question_id: id,
            answer_id: answer.id,
            section_id: game.current_section,
            score: section.score,
            team_id: game?.team?.id          
        })
        
    }

    render(){
        const {id, question, game, section, answers, onSelect = console.debug} = this.props;
        const {confirmPrompt, confirmTitle, onConfirm} = this.state;

        const gameIsStarted = Boolean(game.started_at);        
        const sectionIsStarted = Boolean(section?.started_at);
        const gameIsFinished = Boolean(game.finished_at);

        const answer = Array.isArray(game.answers) ? game.answers.find( ga => ga.question_id === id ) : null;

        console.debug("game question:", question, "answer:", answer)

        return(<div className="question-item">
            <Typography variant="h3" className="flex row items-center content-space-between  m-top-4-forced m-bottom-4-forced color-teal"> {question}
                <Button variant="contained" size="small" className="" component={Link} to={`/game/${game.id}`}>Назад</Button>
            </Typography>
            {(!gameIsStarted || (gameIsStarted && !sectionIsStarted)) && <Alert severity='warning'>В настоящий момент вы не можете отвечать на вопросы</Alert>}
            {!answer && sectionIsStarted  && Array.isArray(answers) && <List>
                    {answers.map( a => <MenuItem onClick={() =>  this.setState({
                        confirmTitle: "Подтвердите выбор ответа",
                        confirmPrompt: `Ваш ответ - "${a.text}"?`,
                        onConfirm: () => { 
                            this.closeConfirmFialog();
                            this.answerSelected(a);
                        }
                    })}>{a.text}</MenuItem>)}
                </List>}
                {answer && <Alert severity='success'>Ваш ответ зарегистрирован, вы больше не можете его изменить. В случае правильного ответа команда получит {answer.score} очков </Alert>}
            { answer && gameIsStarted && Array.isArray(answers) && <List>
                    {answers.map( a => <MenuItem className={ answer.answer_id === a.id ? 'selected' : 'not-selected'} >{a.text}</MenuItem>)}
                </List>}
            { (!gameIsStarted || (gameIsStarted && !gameIsFinished && !sectionIsStarted)) && Array.isArray(answers) && <List>
                    {answers.map( a => <MenuItem className={ 'not-selected'} >{a.text}</MenuItem>)}
                </List>}
            <ConfirmDialog 
            open={Boolean(confirmTitle)} 
            header={confirmTitle} 
            prompt={confirmPrompt} 
            onConfirm={onConfirm} 
            onClose={this.closeConfirmFialog}/>
        </div>)
    }
}

export default GameQuestion