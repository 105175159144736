import { createStore, applyMiddleware, compose, combineReducers } from "redux"
import thunk from 'redux-thunk'
import auth  from './authReducers'
import db from './dbReducers'
import ui from './uiReducers'

const root = combineReducers({ 
    auth,
    ui,
    db
});

const store  = createStore(root, compose(applyMiddleware(thunk)))
export default store; 